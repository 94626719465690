import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.ico"
//import { useStaticQuery, graphql } from "gatsby"

//import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          Made By Dmitri{" "}
          <span role="img" aria-label="sparkle emoji">
            ✨
          </span>{" "}
          Copyright © {new Date().getFullYear()}, All rights reserved.
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
